import './NewsPanel.css';
import MarkdownSpan from "../../MarkdownSpan/MarkdownSpan";

import { Link } from "react-router-dom";

export default function NewsPanel({ item, setNewsPage }) {
    return (
        <div className="news__card" key={item.metadata.uid}>
            <img src={item.previewImage} className="news__card__image" alt="Новость" />
            <div className="news__card__content">
                <p className="news__card__title">{item.title}</p>
                <p><MarkdownSpan markdownText={item.description ? item.description : ' '} /></p>
                <div className='news__card__container_button'>
                    <Link to={`/news/${item.metadata.uid}`}>
                        <button onClick={() => setNewsPage(item)} className='newsCardButton'>Подробнее</button>
                    </Link>
                </div>
            </div>

        </div>
    )
}
