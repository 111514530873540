import './Partners.css';

export default function Partners() {
    return (
        <section id='Partners'>
            <div className='partners'>
                <h1>СТАТЬ ПАРТНЕРОМ</h1>
                <br />
                <p>
                    Слоган нашей компании — «ОПЫТ. НАДЕЖНОСТЬ. ЦЕНЫ. СЕРВИС.»
                    В этом наш принцип работы с Клиентами — мы видим в наших Партнерах надежных и верных друзей.
                    Мы поставляем наше оборудование более 20-ти лет. Плодотворно сотрудничаем со строительными
                    организациями, монтажными организациями, сферой ЖКХ и торговыми предприятиями, специализирующиеся на продаже нашей продукции.
                </p>
                <br />
                <strong>«Экопром-Строй» предлагает своим Партнерам:</strong>
                <ul>
                    <li>Гибкую и понятную систему скидок на продукцию;</li>
                    <li>Постоянную коммуникацию;</li>
                    <li>Техническую поддержку и гарантийно-сервисные обязательства.</li>
                </ul>
                <br />
                <h4>Размеры скидок и варианты сотрудничества оговариваются индивидуально.</h4>

            </div>
        </section>
    )
}