import './Contacts.css'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export default function Contacts() {
    return (
        <section id="Contacts">
            <div className="contacts">
                <div className="contacts-office">
                    <div className="infobanner">
                        <div className="infobanner-content">
                            <h3 className="infobanner-title">Офис</h3>
                            <div className="info-block">
                                <span className="info-block-title">Адрес:</span>
                                <span className="info-block-adres">141013,  Московская область, г. Мытищи, Силикатная улица, д. 36, каб. 317</span>
                            </div>
                            <div className="info-block">
                                <span className="info-block-title">Телефоны:</span>
                                <span className="info-block-adres">+7 (495) 746-81-40<br /> +7 (985) 783-67-94<br /> +7 (916) 017-74-08</span>
                            </div>
                            <div className="info-block"><span className="info-block-title">Email:</span> <span className="info-block-adres">info@ekoprom.com</span>
                            </div>

                        </div>
                    </div>
                    <YMaps>
                        <div className='contacts-officeMap'>
                            <Map defaultState={{ center: [55.94, 37.78], zoom: 12 }}>
                                <Placemark geometry={[55.941496, 37.780661]} />
                            </Map>
                        </div>
                    </YMaps>
                </div>

                <div className="info-block contacts-description">
                    <span className="info-block-title">Как найти наш офис</span>
                    <span className="info-block-adres">Наш офис находится на территории завода «Стройперлит».
                        Чтобы проехать на территорию завода на автомобиле, необходимо будет предварительно позвонить нам, чтобы мы выписали пропуск. Наш менеджер встретит ваш автомобиль и покажет куда припарковаться.
                        Вы можете припарковаться напротив здания проходной завода и попасть на территорию пешком. Нужно будет на проходной выписать пропуск на ваше  имя, для этого понадобится паспорт или
                        водительское удостоверение.</span>
                </div>

                <div className="contacts-office">
                    <div className="infobanner">
                        <div className="infobanner-content">
                            <h3 className="infobanner-title">Производство</h3>
                            <div className="info-block">
                                <span className="info-block-title">Адрес:</span>
                                <span className="info-block-adres">141150, Московская область, городской округ Лосино-Петровский,<br /> деревня Осеево, Центральная улица, д. 25</span>
                            </div>
                            <div className="info-block">
                                <span className="info-block-title">Телефоны:</span>
                                <span className="info-block-adres">+7 (495) 746-81-40<br /> +7 (985) 783-67-94<br /> +7 (916) 017-74-08</span>
                            </div>
                        </div>
                    </div>
                    <YMaps>
                        <div className='contacts-officeMap'>
                            <Map defaultState={{ center: [55.882, 38.185], zoom: 14 }}>
                                <Placemark geometry={[55.881571, 38.185388]} />
                            </Map>
                        </div>
                    </YMaps>
                </div>
            </div>
        </section>
    )
}