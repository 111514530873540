import './Services.css'

export default function Services() {
    return (
        <section id="services">
            <div className="services">

                <div className="services__block">
                    <h3 className="services__subtitle">Комплектация цехов ротоформования</h3>

                    <p className="services__paragraph">
                        Предлагаем полный спектр услуг, связанных с организацией производства крупногабаритных пластиковых изделий методом ротационного формования.
                        <br />
                        Все оборудование изготавливается в России на собственной производственной базе.
                        <br />
                        Компания предлагает клиентам в России и в странах СНГ:<br />
                        - производство и поставку установок ротационного формования и форм (оснастку);
                        <br />
                        - помощь в выборе необходимых установок ротационного формования и дополнительного оборудования;
                        <br />
                        - консультации и помощь в установке и подключении оборудования;
                        <br />
                        - обеспечение технической поддержки нашим клиентам, помощь в обслуживании и ремонте установок ротационного формования, заказ и поставку запасных частей;
                        <br />
                        - обеспечение сырьем для производства изделий;
                        <br />
                        - разработка изделий, производимых методом ротационного формования.
                    </p>
                </div>

                <div className="services__block">
                    <h3 className="services__subtitle">Изготовление металлоконструкций</h3>

                    <p className="services__paragraph">
                        Предлагаем широкий спектр услуг по проектированию и изготовлению всевозможных металлоконструкций, ограждений, металлических каркасов промышленного и
                        индивидуального применения согласно ТЗ заказчика. Наша компания разработает конструкторскую документацию, изготовит любые металлоконструкции с учетом
                        нормативов и потребностей клиента. Большой опыт работы, собственный станочный парк и квалифицированные специалисты позволяют нам гарантировать высокое
                        качество услуг, оказываемых нашей компанией.
                    </p>
                </div>

                <div className="services__block">
                    <h3 className="services__subtitle">Помол и колорирование полиэтилена</h3>

                    <p className="services__paragraph">
                        Наша компания обладает большим опытом по подготовке полиэтилена для использования в процессе производства пластиковых изделий методом ротационного формования.
                        Мы применяем современное оборудование, которое позволяет перерабатывать полиэтиленовые гранулы в порошковую фракцию. Владельцам собственных производств
                        предлагаем услугу по переработке полиэтилена - помол и окрашивани. Помол полиэтилена осуществляется на современном высокотехнологичном оборудовании, позволяющем
                        получить измельченное сырье соответствующего качества, которое необходимо для ротоформовочного производства.
                        Благодаря использованию современных безопасных красителей наша компания имеет возможность предложить заказчику сырье любого цвета согласно таблице цветов RAL.
                    </p>
                </div>

                <div className="services__block">
                    <h3 className="services__subtitle">Проектирование и изготовление пластиковых изделий на заказ</h3>

                    <p className="services__paragraph">
                        <h4>Почему мы?</h4>
                        <ul className='services__ul'>
                            <li>Вы получите продукцию, соответствующую вашим запросам;</li>
                            <li>изделия станут вашей интеллектуальной и товарной собственностью;</li>
                            <li>квалифицированный персонал в оговоренные сроки разработает и изготовит заказанные вамии изделия.</li>
                        </ul>

                        <h4>«Экопром-Строй» изготавливает на заказ:</h4>
                        <ul className='services__ul'>
                            <li>Емкости для коммунальной, сельскохозяйственной и другой спецтехники;</li>
                            <li>Баки для хранения дизельного топлива и технических масел;</li>
                            <li>Топливные баки и другие изделия для тракторов и спецтехники;</li>
                            <li>Крупногабаритные корпусные детали для автотехники;</li>
                            <li>Навесное и прицепное оборудование.</li>
                        </ul>

                        <h4>Продукцию из полиэтилена отличает ряд преимуществ:</h4>
                        <ul className='services__ul'>
                            <li>Высокая коррозионная стойкость, низкое водопоглощение;</li>
                            <li>Высокая химическая стойкость к большинству агрессивных сред;</li>
                            <li>Возможность эксплуатации в широком диапазоне температур;</li>
                            <li>Стойкость к УФ излучению (за счет специальных присадок, добавляемых при производстве);</li>
                            <li>Высокие диэлектрические свойства;</li>
                            <li>Прочность, гибкость, упругость;</li>
                            <li>Низкие адгезионные свойства.</li>
                        </ul>
                    </p>
                </div>
            </div>
        </section>
    )
}
